<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Additional Info
			</div>
			<div class="right">
				<v-ons-toolbar-button
					icon="md-refresh"
					@click="resetInput"
				/>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<div class="content pt-3 px-3 pb-5">
			<h5 class="text-muted mt-0 mb-4">
				Fill all required fields *
			</h5>
			<ValidationObserver ref="validator">
				<ValidationProvider
					v-for="(label, index) in selectedPriceCategory.custom_attributes.labels"
					:key="index"
					v-slot="{ errors }"
					:name="label.name.toLowerCase().split(' ').join('-')"
					:rules="{
						required: label.is_required === 'true'
					}"
				>
					<v-ons-input
						v-model="additionalInfo[label.name.toLowerCase().split(' ').join('-')]"
						:name="label.name.toLowerCase().split(' ').join('-')"
						:placeholder="label.name + (label.is_required === 'true' ? ' *' : '')"
						class="additional-info-input w-100"
						:class="{
							'mb-4': index < selectedPriceCategory.custom_attributes.labels.length
						}"
						float
					/>
					<span class="text-danger">{{ errors[0] }}</span>
				</ValidationProvider>
			</ValidationObserver>
			<v-ons-button
				class="position-fixed rounded-0"
				style="bottom: 0; left: 0; right: 0; z-index: 5"
				@click="saveAdditionalInfo"
			>
				Save
			</v-ons-button>
		</div>
	</v-ons-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			selectedPriceCategory() {
				return this.$store.state.selectedPriceCategory
			},
			additionalInfo() {
				return this.$store.state.additionalInfo
			}
		},
		methods: {
			resetInput() {
				document.querySelectorAll('.additional-info-input').forEach(input => {
					input.value = ''
				})
			},
			async saveAdditionalInfo() {
				await this.$refs.validator.validate().then(() => {
					this.pageStack.pop()
				})
			}
		}
	}
</script>
