if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then(registration => {
		let serviceWorker

		if (registration.installing) {
			serviceWorker = registration.installing
		} else if (registration.waiting) {
			serviceWorker = registration.waiting
		} else if (registration.active) {
			serviceWorker = registration.active
		}

		if (serviceWorker) {
			serviceWorker.addEventListener('statechange', e => {
				if (e.target.state === 'activated')
					window.location.reload()
			})
		}
	})

	window.addEventListener('load', () => {
		navigator.serviceWorker.register(`/sw.js?version=${process.env.VERSION}`)
			.then(() => console.log('Service worker registered successfully'))
			.catch(err => console.error('Service worker registration failed', err))
	})
}
