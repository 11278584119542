<template>
	<div>
		<no-ssr>
			<nuxt />
		</no-ssr>
	</div>
</template>

<script>
	export default {
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			}
		},
		beforeMount() {
			this.$ons.platform.select('android')
			this.$store.commit('setState', {
				key: 'appVersion',
				value: this.$bridge.getVersion()
			})

			if (this.$sentry) {
				this.$sentry.configureScope(scope => {
					scope.setTag('version', this.$store.state.appVersion)
				})
			}
			
			this.backgroundSync()
			this.$socket.on('model-update', model => {
				const options = this.getSyncOptions(model)
				if (this.isLoggedIn && Object.keys(options).length) {
					options.silent = true
					this.sync(options).then(() => {
						this.key++
					})
				}
			})
		}
	}
</script>
