<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<div v-if="subscription.expires_in_seconds > 0 && !(subscription.is_trial && $bridge.getLocalStorage('orderCount') >= subscription.limitations.order)">
					<v-ons-back-button />
				</div>
			</div>
			<div class="center">
				Subscription
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<div class="background bg-white" />
		<v-ons-row class="h-100 p-4">
			<v-ons-col vertical-align="center">
				<div class="text-center mb-4">
					<i class="la la-warning la-4x text-danger" />

					<h4 v-if="subscription.is_trial">
						You have reached your maximum quota of 10 orders per day on trial subscription!
					</h4>
					<h4 v-else>
						Your subscription
						<span v-if="subscription.expires_in_seconds <= 0">
							has expired!
						</span>
						<span v-else-if="subscription.expires_in_days">
							expires in {{ subscription.expires_in_days }} day{{
								subscription.expires_in_days > 1 ? 's' : ''
							}}!
						</span>
						<span v-else>
							will expire today!
						</span>
					</h4>
					<p class="mb-0">
						To keep using POSBytz without interruption, Please visit
						<a
							href="#"
							@click="$bridge.openExternalUrl(backendDomain)"
						>
							{{ backendDomain }}
						</a>
						to {{ subscription.is_trial ? 'upgrade' : 'renew' }} your subscription.
					</p>
					<v-ons-row class="mt-4">
						<v-ons-button
							:disabled="processing"
							class="w-100 mb-4"
							@click="checkSubscriptionStatus"
						>
							Check Subscription Status
							<span
								v-if="processing"
								class="float-right"
							>
								<v-ons-icon
									icon="md-spinner"
									spin
									size="18px"
								/>
							</span>
						</v-ons-button>
						<v-ons-button
							v-if="subscription.expires_in_days >= 1 && !(subscription.is_trial && $bridge.getLocalStorage('orderCount') >= subscription.limitations.order)"
							:disabled="processing"
							class="w-100 mb-4"
							@click="proceedToCheckout"
						>
							Proceed
						</v-ons-button>
					</v-ons-row>
				</div>
			</v-ons-col>
		</v-ons-row>
	</v-ons-page>
</template>
<script>
	import Checkout from '~/components/checkout'
  export default {
    name: 'Subscription',
		props:{
			source: {
				type: String,
				required: true
			},
			orderProp: {
				type: Object,
				default() {
					return {}
				}
			}
		},
    data() {
			return {
				backendDomain: process.env.BACKEND_DOMAIN,
				processing:false
			}
		},
    computed: {
      subscription: {
				get() {
					return this.$store.state.subscription
				},
				set(value) {
					this.$store.commit('setState', {
						key: 'subscription',
						save: true,
						value
					})
				}
			}
    },destroyed() {
			this.$store.commit('setState', {
					key: 'subscription',
					value: {
						...this.subscription,
						show_modal: false
					}
				})
		},
		methods:{
			checkSubscriptionStatus(){
				this.processing = true
				this.$store.dispatch('getSubscription')
				setTimeout(() => {
					this.processing = false
				},6000)
			},
			proceedToCheckout(){
				this.$emit('push', {
					extends: Checkout,
					onsNavigatorProps: {
						source: this.source,
						orderProp: this.orderProp
					}
				})
			}
		}
  }
</script>
