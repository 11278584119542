<template>
	<v-ons-action-sheet
		id="table-orders-action"
		:visible.sync="orders.length > 0"
		cancelable
		title="Table Orders"
		@posthide="$emit('update:showTableOrdersModal', false)"
	>
		<div class="bg-white px-4 pb-2">
			<p class="m-0">
				Please select an order to continue.
			</p>
			<div class="content">
				<v-ons-list v-if="orders.length">
					<div
						v-for="(order, i) in orders"
						:key="i"
						tappable
						class="display-fle m-0 p-0 mt-3"
						@click="$emit('update:processOrder', i)"
					>
						<ons-row class="border-no p-0">
							<ons-col>
								<Small>Bill Number - {{ parseInt(order.receipt_code.substr(-4)) }}</Small>
							</ons-col>
							<ons-col>
								<Small><span v-if="order.customer">{{ order.customer.first_name }}</span>
									<span v-else> - </span></Small>
							</ons-col>
						</ons-row>
						<ons-row class="border-no p-0">
							<ons-col>
								<Small>Items - {{ order.items.length }}</Small>
							</ons-col>
							<ons-col>
								<Small>{{ $moment.utc(order.created_at).local().format('lll') }}</Small>
							</ons-col>
						</ons-row>
					</div>
				</v-ons-list>
				<p class="or mt-2">
					or
				</p>
				<v-ons-button
					class="new-order-button"
					@click="$emit('update:processOrder')"
				>
					Create New Order
				</v-ons-button>
			</div>
		</div>
	</v-ons-action-sheet>
</template>

<script>
	export default {
    props: {
			orders: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
        actionSheetVisible: false
			}
		},
		mounted(){
		},
    methods: {
    }
	}
</script>

<style lang="scss" scoped>
	.list-item__center{
		background: none
	}
	.or{
		text-align: center;
    margin: 0;
	}
	.new-order-button{
		margin: 8px 0px;
    display: block;
	}
</style>
