import en from 'vee-validate/dist/locale/en.json'
import { extend, localize } from 'vee-validate';
import { required, email, min, max, length, numeric, digits, alpha_num, min_value , max_value} from 'vee-validate/dist/rules';

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('numeric', numeric)
extend('digits', digits)
extend('alpha_num', alpha_num)
extend('min_value', min_value)
extend('max_value', max_value)
extend('length', length)

extend('password', {
	validate: (value, { other }) => value === other,
	message: 'The password confirmation does not match.',
	params: [{ name: 'other', isTarget: true }]
})

extend('decimal', {
  params: ['decimals', 'separator'],
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(value)
    }

    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

    if (!regex.test(value)) {
      return false
    }

    const parsedValue = parseFloat(value)

    // eslint-disable-next-line
    return parsedValue === parsedValue;
  },
  message: (field, { decimals }) => {
    return `The ${field} field allow only numeric or ${decimals} decimal points`
  }
})

extend('mobile', {
	validate: (value, countryCode) => {
		switch (countryCode[0]) {
			case 'AE':
				return /^(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/.test(value)
				break
			case 'IN':
				return /^[6-9]\d{9}$/.test(value)
				break
			case 'ZA':
				return /^[6-8]\d{8}$/.test(value)
				break
			default:
				return true
		}
	},
  message: `Please enter a valid mobile`
});

localize({ en })
