<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Choose Category
			</div>
		</v-ons-toolbar>
		<div class="position-relative">
			<div
				class="position-fixed p-2"
				:style="{
					left: 0,
					right: 0,
					backgroundColor: '#eee',
					zIndex: 1
				}"
			>
				<v-ons-search-input
					placeholder="Search Category"
					class="w-100"
					@input="searchCategories($event.currentTarget.value.trim())"
				/>
			</div>
			<section style="padding-top: 65px; margin-left:8px; margin-right:8px; background:white">
				<div
					v-for="(category, index) in categories"
					:key="index"
					class="category-view"
				>
					<div
						@click="setSelectedCategoryId(category)"
					>
						{{ category.name }}
					</div>
				</div>
			</section>
		</div>
	</v-ons-page>
</template>

<script>
	export default {
		name: 'Categories',
		props: {
			type: {
				type: String,
				default: ''
			},
			categoryId: {
				type: Number,
				default: 0
			},
			emitCategory: {
				type: Function,
				required: true
			}
		},
		data() {
			return {
				searchTimer: null,
				categories: []
			}
		},
		computed: {
			deviceId() {
				return this.$store.state.deviceId
			}
		},
		beforeMount() {
			this.getCategories(null)
		},
		methods: {
			getCategories(searchTerm) {
				if (this.type === 'filter')
					this.categories = [{ id: null, name: 'All'}]

				let categories = this.$bridge.getCategories(this.deviceId, JSON.stringify({
					search_term: searchTerm
				}))

				categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data
				this.categories = this.categories.concat(categories)
			},
			searchCategories(searchTerm) {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getCategories(searchTerm)
				}, 100)
			},
			setSelectedCategoryId(category) {
				this.$emit('pop')
				this.emitCategory(category)
			}
		}
	}
</script>
